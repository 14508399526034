<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>

      <div class="event-timer-component">
        <div class="event-timer-title">
          {{ title }}
        </div>

        <div class="event-timer-subtitle">
          {{ subtitle }}
        </div>

        <button class="button is-primary event-timer-button-mobile mt-2" v-if="buttonVisible" @click="scrollToModule(buttonScrollToModule)">
          {{ buttonText }}
        </button>

        <div class="event-timer-wrapper">
          <event-timer :date="timerDate"></event-timer>
          <button class="button is-primary event-timer-button" v-if="buttonVisible" @click="scrollToModule(buttonScrollToModule)">
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { mapGetters, mapState } from "vuex";
import EventTimer from "@/web/components/eventinfo/EventTimer";
import { navScrollTo } from "@/shared/utils";

export default {
  name: "EventTimerComponent",
  mixins: [LpConfigMixin],
  components: { EventTimer, Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("agendaSessionQuestions", ["getFirstSession"]),
    ...mapGetters(["lpSettings", "visibleModules"]),
    ...mapState(["event"]),

    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,
    eventTimerModuleFields: () => LpConfigConstants.EVENT_TIMER_MODULE_FIELDS,

    timerConfig() {
      return this.lpSettings[LpConfigConstants.ROOT_FIELDS.TIMER] || {};
    },

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    title() {
      return this.getCustomTranslation(this.moduleData, this.commonModuleFields.TITLE);
    },

    subtitle() {
      return this.getCustomTranslation(this.moduleData, this.eventTimerModuleFields.SUBTITLE);
    },

    buttonVisible() {
      return this.getFieldValueOrDefault(this.moduleData, this.eventTimerModuleFields.BUTTON_VISIBLE, false) && this.scrollModuleExists;
    },

    buttonText() {
      return this.getCustomTranslation(this.moduleData, this.eventTimerModuleFields.BUTTON_TEXT);
    },

    buttonScrollToModule() {
      return this.getFieldValueOrDefault(
        this.moduleData,
        this.eventTimerModuleFields.BUTTON_SCROLL_TO_MODULE,
        `#module-${this.moduleData[LpConfigConstants.COMMON_MODULE_FIELDS.ORDER] + 1}`
      );
    },

    timerDate() {
      return (
        this.timerConfig[LpConfigConstants.TIMER_FIELDS.DATE] ||
        (this.getFirstSession && this.getFirstSession.time_start) ||
        this.event.start_date
      );
    },

    scrollModuleExists() {
      let foundModules = this.visibleModules.map(it => `#module-${it.id}`).filter(it => it === this.buttonScrollToModule);
      return foundModules.length;
    },
  },

  methods: {
    scrollToModule(hash) {
      navScrollTo(hash, "smooth");
    },
  },
};
</script>

<style scoped>
.module {
  padding-bottom: 60px;
}
</style>
