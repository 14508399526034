import { render, staticRenderFns } from "./PurchaseFinalStep.vue?vue&type=template&id=02794206&scoped=true&"
import script from "./PurchaseFinalStep.vue?vue&type=script&lang=js&"
export * from "./PurchaseFinalStep.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseFinalStep.vue?vue&type=style&index=0&id=02794206&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02794206",
  null
  
)

export default component.exports