<template>
  <article class="module">
    <div class="container">
      <separator class="separator is-primary" v-if="separatorVisible"></separator>
      <h2 class="title primary-text-color event-title-text has-text-centered mb-2" v-html="title"></h2>
      <p class="has-text-centered has-text-weight-semibold description-text" v-html="description"></p>
    </div>
  </article>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Separator from "@/web/components/shared/Separator";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";

export default {
  name: "EventDescriptionComponent",
  mixins: [LpConfigMixin],
  components: { Separator },

  props: {
    moduleData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters("components", ["eventInfoComponent"]),

    moduleFields: () => LpConfigConstants.EVENT_DESCRIPTION_FIELDS,
    commonModuleFields: () => LpConfigConstants.COMMON_MODULE_FIELDS,

    separatorVisible() {
      return !this.getFieldValueOrDefault(this.moduleData, this.commonModuleFields.SEPARATOR_DISABLED, false);
    },

    description() {
      return this.getCustomTranslation(this.moduleData, this.moduleFields.CUSTOM_TEXT, this.event.description);
    },

    title() {
      return this.getCustomTranslation(
        this.moduleData,
        this.commonModuleFields.TITLE,
        (this.eventInfoComponent && this.eventInfoComponent.label) || this.$t("common.about_event"),
      );
    },
  },
};
</script>

<style scoped lang="scss">
.description-text {
  white-space: pre-wrap;
  color: var(--global-description-text-color);
  font-size: var(--event-introduction-desc-font-size);
}

.event-title-text {
  white-space: pre-wrap;
  font-size: var(--event-introduction-title-font-size);
}
</style>
