const COMPONENT_ID_SEGMENT = 8;
const COMPONENT_TYPE_SEGMENT = 9;

const EXHIBITOR_SEGMENT_NAME = "exhibitor_component";
const EXHIBITOR_ID_SEGMENT = 10;

const FEED_WALL_SEGMENT_NAME = "feed_wall_component";
const FEED_WALL_CHANNEL_ID_SEGMENT = 10;
const FEED_WALL_THREADS_SEGMENT = 11;
const FEED_WALL_THREADS_SEGMENT_NAME = "threads";
const FEED_WALL_THREAD_ID_SEGMENT = 12;

const QUIZZES_SEGMENT_NAME = "survey_quiz_component";
const QUIZ_ID_SEGMENT = 10;

const BUSINESS_MATCHING_COMPONENT_SEGMENT_NAME = "business_matching_component";
const BUSINESS_MATCHING_TAB_SEGMENT_POSITION = 10;
const BUSINESS_MATCHING_ACCEPTED_TAB_SEGMENT_NAME = "accepted";
const BUSINESS_MATCHING_PENDING_TAB_SEGMENT_NAME = "pending";
const BUSINESS_MATCHING_MEETING_ID_SEGMENT_POSITION = 11;
const BUSINESS_MATCHING_MEETING_ROOM_SEGMENT_POSITION = 11;
const BUSINESS_MATCHING_MEETING_ROOM_SEGMENT_NAME = "room";
const BUSINESS_MATCHING_MEETING_ROOM_ID_SEGMENT_POSITION = 12;

/***************** COMMON *****************/

export function getComponentId(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= COMPONENT_ID_SEGMENT) {
    return Number.parseInt(segments[COMPONENT_ID_SEGMENT]);
  }
  return null;
}

/***************** EXHIBITORS *****************/

export function isExhibitorComponent(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= COMPONENT_TYPE_SEGMENT) {
    return segments[COMPONENT_TYPE_SEGMENT] === EXHIBITOR_SEGMENT_NAME;
  }
  return false;
}

export function getExhibitorId(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= EXHIBITOR_ID_SEGMENT) {
    return Number.parseInt(segments[EXHIBITOR_ID_SEGMENT]);
  }
  return null;
}

/***************** QUIZZES *****************/

export function isQuizzesComponent(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= COMPONENT_TYPE_SEGMENT) {
    return segments[COMPONENT_TYPE_SEGMENT] === QUIZZES_SEGMENT_NAME;
  }
  return false;
}

export function getQuizId(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= QUIZ_ID_SEGMENT) {
    return Number.parseInt(segments[QUIZ_ID_SEGMENT]);
  }
  return null;
}

/***************** FEEDWALL *****************/

export function isFeedWallComponent(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= COMPONENT_TYPE_SEGMENT) {
    return segments[COMPONENT_TYPE_SEGMENT] === FEED_WALL_SEGMENT_NAME;
  }
  return false;
}

export function getFeedWallChannelId(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= FEED_WALL_CHANNEL_ID_SEGMENT) {
    return Number.parseInt(segments[FEED_WALL_CHANNEL_ID_SEGMENT]);
  }
  return null;
}

export function getFeedWallThreadId(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= FEED_WALL_THREAD_ID_SEGMENT) {
    if (segments[FEED_WALL_THREADS_SEGMENT] === FEED_WALL_THREADS_SEGMENT_NAME) {
      return Number.parseInt(segments[FEED_WALL_THREAD_ID_SEGMENT]);
    }
  }
  return null;
}

/***************** BUSINESS MATCHING *****************/

export function isBusinessMatchingComponent(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= COMPONENT_TYPE_SEGMENT) {
    return segments[COMPONENT_TYPE_SEGMENT] === BUSINESS_MATCHING_COMPONENT_SEGMENT_NAME;
  }
  return false;
}

export function hasBusinessMatchingAcceptedTab(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= BUSINESS_MATCHING_TAB_SEGMENT_POSITION) {
    return segments[BUSINESS_MATCHING_TAB_SEGMENT_POSITION] === BUSINESS_MATCHING_ACCEPTED_TAB_SEGMENT_NAME;
  }
  return false;
}

export function hasBusinessMatchingPendingTab(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= BUSINESS_MATCHING_TAB_SEGMENT_POSITION) {
    return segments[BUSINESS_MATCHING_TAB_SEGMENT_POSITION] === BUSINESS_MATCHING_PENDING_TAB_SEGMENT_NAME;
  }
  return false;
}

export function hasBusinessMatchingMeetingVideoCallRoom(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= BUSINESS_MATCHING_MEETING_ROOM_ID_SEGMENT_POSITION) {
    return segments[BUSINESS_MATCHING_MEETING_ROOM_SEGMENT_NAME] === BUSINESS_MATCHING_MEETING_ROOM_SEGMENT_NAME;
  }
  return false;
}

export function getBusinessMatchingMeetingVideoCallRoom(deepLink) {
  const segments = deepLink.split("/");
  if (hasBusinessMatchingMeetingVideoCallRoom(deepLink)) {
    return segments[BUSINESS_MATCHING_MEETING_ROOM_SEGMENT_POSITION] === BUSINESS_MATCHING_PENDING_TAB_SEGMENT_NAME;
  }
  return false;
}

export function getBusinessMatchingMeetingId(deepLink) {
  const segments = deepLink.split("/");
  if (segments.length - 1 >= BUSINESS_MATCHING_MEETING_ID_SEGMENT_POSITION) {
    const tabSegment = segments[BUSINESS_MATCHING_TAB_SEGMENT_POSITION];
    if (tabSegment === BUSINESS_MATCHING_PENDING_TAB_SEGMENT_NAME || tabSegment === BUSINESS_MATCHING_ACCEPTED_TAB_SEGMENT_NAME) {
      return segments[BUSINESS_MATCHING_MEETING_ID_SEGMENT_POSITION];
    }
  }
  return false;
}
